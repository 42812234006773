import React from "react";
import { Route, Redirect } from "react-router-dom";
import useAuth from "hooks/useAuth";
import { NOT_FOUND_PATH } from "configs/AppConfig";

export const RoleBasedRouting = (props) => {
  const { exactAuth, AuthNeeded, ...rest } = props;

  const { isAuth, exact } = useAuth([AuthNeeded]);

  if (exactAuth) {
    if (exact) {
      return <Route {...rest}>{props.children}</Route>;
    } else {
      return (
        <Route>
          <Redirect to={NOT_FOUND_PATH} from={props.location.pathname} />
        </Route>
      );
    }
  } else {
    if (isAuth) {
      return <Route {...rest}>{props.children}</Route>;
    } else {
      return (
        <Route>
          <Redirect to={NOT_FOUND_PATH} from={props.location.pathname} />
        </Route>
      );
    }
  }
};

export default RoleBasedRouting;
