import React, { useState } from "react";
import {Button,  Input, Modal, Switch} from "antd";
import UserService from "../../../services/UserService";

export default function MeetModal({visible, setVisible}) {

    const [users, setUsers] = useState([]);
    const [isPublic, setIsPublic] = useState(false);
    const [loading, setLoading] = useState(false);
    const [stopMeetingLoading, setStopMeetingLoading] = useState(false);

    const handleStartMeeting = () => {
        setLoading(true)
        UserService.startMeeting({users, isPublic})
            .then((res) => {
                window.open(res.data.roomName, '_blank');
                setVisible(false)
            })
            .finally(() => {setLoading(false)})
    }

    const handleStopMeeting = () => {
        setStopMeetingLoading(true)
      UserService.StopMeeting()
            .then((res) => {
                console.log(res)
            })
          .finally(() => {setStopMeetingLoading(false)})
    }

    return (
        <Modal
        title={'Meet Settings'}
        visible={visible}
        open={visible}
        onCancel={() => setVisible(false)}
        onOk={() => setVisible(false)}
        footer={[
            <Button key="back" className="btn btn-light" onClick={() => setVisible(false)}>
                Cancel
            </Button>,
            <Button danger onClick={handleStopMeeting} loading={stopMeetingLoading}>
                Stop Old Meeting
            </Button>,
            <Button type={'primary'} onClick={handleStartMeeting} loading={loading}>
                Start Meet
            </Button>,
        ]}
        >
            <div>
                <div className={'mb-3'}>
                    <h5>Enable Public</h5>
                    <Switch onChange={(value => {
                        setIsPublic(value)
                    })}  />
                </div>
                {!isPublic ? <>
                    <h5>add users</h5>
                    <div className={'d-flex align-items-center justify-content-between  m-1'}>
                        <Input id={'userCode'}/>
                        <Button type={'primary'} onClick={() => {
                            setUsers([document.getElementById('userCode').value, ...users])
                            document.getElementById('userCode').value = ''
                        }}>Add</Button>
                    </div>
                    <div className={'mt-4'} style={{maxHeight: '50vh', overflowY: 'scroll'}}>
                        {users.map((user) => (
                            <div className={'d-flex align-items-center justify-content-between  m-1'}>
                                <p>{user}</p>
                                <Button danger onClick={() => {
                                    setUsers(users.filter((u) => u !== user))
                                }}>Remove</Button>
                            </div>
                        ))}
                    </div>
                </> : null}
            </div>

        </Modal>
    )
}
