export const SIGNIN = 'SIGNIN';
export const AUTHENTICATED = 'AUTHENTICATED';
export const SIGNOUT = 'SIGNOUT';
export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS'
export const SIGNUP = 'SIGNUP';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS'
export const SHOW_AUTH_MESSAGE = 'SHOW_AUTH_MESSAGE'
export const HIDE_AUTH_MESSAGE = 'HIDE_AUTH_MESSAGE'
export const SHOW_LOADING = 'SHOW_LOADING'
export const AUTH_TOKEN = 'auth_token'
export const SIGNIN_WITH_GOOGLE = 'SIGNIN_WITH_GOOGLE'
export const SIGNIN_WITH_GOOGLE_AUTHENTICATED = 'SIGNIN_WITH_GOOGLE_AUTHENTICATED'
export const SIGNIN_WITH_FACEBOOK = 'SIGNIN_WITH_FACEBOOK'
export const SIGNIN_WITH_FACEBOOK_AUTHENTICATED = 'SIGNIN_WITH_FACEBOOK_AUTHENTICATED'

export const ROLE_MASTER = 'ROLE_MASTER'
export const ROLE_DIGITAL = 'ROLE_DIGITAL'
export const ROLE_SUPERVISOR = "ROLE_SUPERVISOR"
export const ROLE_PRODUCT = 'ROLE_PRODUCT'
export const ROLE_MEDIA = 'ROLE_MEDIA'
export const ROLE_TRAINING = 'ROLE_TRAINING'
export const ROLE_FINANCIER = "ROLE_FINANCIER";
export const ROLE_ORDER = "ROLE_ORDER";
export const ROLE_CUSTOMER = 'ROLE_CUSTOMER'
export const Role_hierarchy = [
    ROLE_MASTER,
    ROLE_SUPERVISOR,
    ROLE_TRAINING,
    ROLE_PRODUCT,
    ROLE_DIGITAL,
    ROLE_MEDIA,
    ROLE_ORDER,
    ROLE_FINANCIER,
    ROLE_CUSTOMER
]

export const SIGNIN_SUCCESS = "SIGNIN_SUCCESS";
export const AUTHENTICATED_PLAYER = "AUTHENTICATED_PLAYER";
export const SET_USER = "SET_USER";
export const GET_USER_ROLE = "GET_USER_ROLE";

/*export const ROLE_USER = "ROLE_USER";
export const ROLE_PLAYER = "ROLE_PLAYER";
export const ROLE_SHOP = "ROLE_SHOP";
export const ROLE_ADMIN = "ROLE_ADMIN";
export const ROLE_COMERCIAL = "ROLE_COMERCIAL";
export const ROLE_OPERATEUR = "ROLE_OPERATEUR";
export const ROLE_CRE_CON = "ROLE_CRE_CON";
export const ROLE_CONT_CON = "ROLE_CONT_CON";
export const ROLE_VAL_CON = "ROLE_VAL_CON";
export const ROLE_SUPERVISER = "ROLE_SUPERVISER";*/


export const CHANGE_CREDIT = "CHANGE_CREDIT";
export const UPDATE_CREDIT = "UPDATE_CREDIT";
export const CHANGE_WINNING = "CHANGE_WINNING";


export const VALIDATE_USER = "VALIDATE_USER";
