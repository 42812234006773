const dev = {
	API_ENDPOINT_URL: 'https://localhost:8000/v1/',
	IMG_SRC : 'https://apitest.admin-arbio.com/images/products/',
	OPENAI_API_KEY : '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'
};

const prod = {
	API_ENDPOINT_URL: 'https://api.admin-arbio.com/v1/',
	IMG_SRC : 'https://api.admin-arbio.com/images/products/',
	OPENAI_API_KEY : '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'
};

const staging = {
	API_ENDPOINT_URL: 'https://apitest.admin-arbio.com/v1/',
	IMG_SRC : 'https://apitest.admin-arbio.com/images/products/',
	OPENAI_API_KEY : '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'
};


const getEnv = () => {
	switch (process.env.REACT_APP_NODE_ENV) {
		case "development":
			return dev;
		case "production":
			return prod;
		case "staging":
			return staging;
		default:
			break;
	}
};

export const env = getEnv();
