import {Form, Input, Modal} from "antd";
import UserService from "services/UserService";

export default ({visible , setShowModal}) => {
    const [form] = Form.useForm()
    const onOk = () => {
        form.validateFields()
            .then(values => {
                UserService.changePassword({currentPassword : values.currentPassword ,newPassword : values.newPassword })
                    .then(resp => {
                        setShowModal(false)
                    })
            })
    }
    return (
        <Modal
            visible={visible}
            onCancel={() => setShowModal(false)}
            onOk={onOk}
            closable={false}
        >
            <Form
                form={form}
                labelCol={{span : 8}}
                labelAlign={'left'}
            >
                <Form.Item label={'Current Password'} name={'currentPassword'} rules={[{ required : true , message : 'please provide current password'}]} >
                    <Input.Password />
                </Form.Item>
                <Form.Item label={'New Password'} name={'newPassword'} rules={[{ required : true , message : 'please provide new password'}]}>
                    <Input.Password />
                </Form.Item>
                <Form.Item
                    name={"confirmPassword"}
                    label={"confirm Password"}
                    dependencies={['newPassword']}
                    rules={[
                        {
                            required: true,
                            message: 'Please confirm your password!',
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('newPassword') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('do not match new password!'));
                            },
                        }),
                    ]}
                    hasFeedback>
                    <Input.Password />
                </Form.Item>
            </Form>
        </Modal>
    )
}