import React, { useState } from "react";
import {Menu, Dropdown, Avatar, Typography, Button} from "antd";
import {connect, useDispatch} from "react-redux";
import {
    SettingOutlined,
    LogoutOutlined,
    VideoCameraOutlined
} from "@ant-design/icons";
import Icon from "components/util-components/Icon";
import { signOut } from "redux/actions/Auth";
import { useHistory } from "react-router-dom";
import useAuth from "hooks/useAuth";
import {ROLE_MASTER, ROLE_MEDIA, ROLE_SUPERVISOR} from "redux/constants/Auth";
import { setLocale } from "utils";
import ChangePasswordModal from "../shared-components/ChangePasswordModal";
import MeetModal from "../shared-components/MeetModal";

export const NavProfile = (props) => {
    const { Title } = Typography;
    const { signOut, username, code ,role } = props;
    const { isAuth: isAuthMaster } = useAuth([ROLE_MASTER]);
    const [showPasswordModal, setShowPasswordModal] = useState(false);
    const [showMeetModal, setShowMeetModal] = useState(false);
    const dispatch = useDispatch();


    let history = useHistory();
    let profileImg = "/img/avatars/thumb-1.png";

    const profileMenu = (
        <div className="nav-profile nav-dropdown">
            <div className="nav-profile-header">
                <div className="d-flex">
                    <Avatar size={50} src={profileImg} />
                    <div className="pl-3">
                        <h4 className="mb-0">{username}</h4>
                        <p className="mb-0">{role}</p>
                    </div>
                </div>
            </div>
            <div className="nav-profile-body">
                <Menu>
                    <Menu.Item
                        key={5}
                        onClick={() => setShowPasswordModal(true)}
                    >
                        <Icon className="mr-3" type={SettingOutlined} />
                        <span className="font-weight-normal">{setLocale(true, "player.nav.profile.password.change")}</span>
                    </Menu.Item>
                    <Menu.Item
                        key={6}
                        onClick={(e) => {
                            dispatch(signOut());
                            history.push("/auth/login");
                        }}
                    >
                        <span>
                          <LogoutOutlined className="mr-3" />
                          <span className="font-weight-normal">{setLocale(true, "player.nav.profile.signout")}</span>
                        </span>
                    </Menu.Item>
                </Menu>
                <ChangePasswordModal visible={showPasswordModal} setShowModal={setShowPasswordModal}/>
            </div>
        </div>
    );

    const handleMeet = () => {
        setShowMeetModal(true)
    }

    return (
        <>
            <Dropdown
                placement="bottomRight"
                overlay={profileMenu}
                trigger={["click"]}
            >
                <Menu className="d-flex align-item-center" mode="horizontal">
                    <Menu.Item key="profile">
                        <Avatar src={profileImg} />
                    </Menu.Item>
                </Menu>
            </Dropdown>
            {[ROLE_MEDIA, ROLE_SUPERVISOR , ROLE_MASTER].includes(role) ?<Menu className="d-flex align-item-center" mode="horizontal">
                <Menu.Item key="meet">
                    <div onClick={handleMeet}>
                        <VideoCameraOutlined/>
                    </div>
                </Menu.Item>
            </Menu> : null}
            <MeetModal visible={showMeetModal} setVisible={setShowMeetModal}/>
        </>
    );
};

const mapStateToProps = ({ auth }) => {
    const { username, role, code } = auth;
    return { username, role, code };
};

export default connect(mapStateToProps, { signOut })(NavProfile);
