import fetch from '../auth/FetchInterceptor'
import PaymentService from "./PaymentService";

const NotificationService = {
}
const ServiceURL = "/notification"

NotificationService.getAll = function (data) {
    return fetch({
        url: ServiceURL+'/getAll',
        method: 'post',
        data:data
    })
}

NotificationService.getNotificationsSetting = function () {
    return fetch({
        url: ServiceURL+'/settings',
        method: 'get',
    })
}

NotificationService.putNotificationsSetting = function (data) {
    return fetch({
        url: ServiceURL+'/settings',
        method: 'put',
        data : data
    })
}

NotificationService.setRead = function (id) {
    return fetch({
        url: ServiceURL+'/read/'+id,
        method: 'put',
    })
}

NotificationService.delete = function (id) {
    return fetch({
        url: ServiceURL+'/delete/'+id,
        method: 'delete',
    })
}

NotificationService.getAdminNotification = function (id) {
    return fetch({
        url: ServiceURL+'/admin',
        method: 'get',
    })
}

NotificationService.clearAdminNotifications = function (id) {
    return fetch({
        url: ServiceURL+'/clearAdmin',
        method: 'get',
    })
}
export default NotificationService
