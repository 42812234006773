import React from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AppLayout from "layouts/app-layout";
import AuthLayout from 'layouts/auth-layout';
import AppLocale from "lang";
import { IntlProvider } from "react-intl";
import { ConfigProvider } from 'antd';
import {APP_PREFIX_PATH, AUTH_PREFIX_PATH, NOT_FOUND_PATH} from 'configs/AppConfig'
import useBodyClass from 'hooks/useBodyClass';
import RoleBasedRouting from "auth/gard/RoleBasedRouting";
import {ROLE_MASTER} from "../redux/constants/Auth";
import ErrorOne from "./errors/error-page-1";
import JwtAuthService from "services/JwtAuthService";

export const Views = (props) => {
  const { locale, location, direction } = props;
  const currentAppLocale = AppLocale[locale];
  useBodyClass(`dir-${direction}`);
  JwtAuthService.refreshToken();
  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}>
      <ConfigProvider locale={currentAppLocale.antd} direction={direction}>
        <Switch>
          <Route exact path="/">
            <Redirect to={AUTH_PREFIX_PATH} />
          </Route>}
          <Route path={AUTH_PREFIX_PATH}>
            <AuthLayout direction={direction} />
          </Route>
          <RoleBasedRouting path={APP_PREFIX_PATH} AuthNeeded={ROLE_MASTER}>
            <AppLayout direction={direction} location={location} />
          </RoleBasedRouting>
          <Route path={NOT_FOUND_PATH}>
            <ErrorOne />
          </Route>
          <Redirect to={APP_PREFIX_PATH} />
        </Switch>
      </ConfigProvider>
    </IntlProvider>
  )
}

const mapStateToProps = ({ theme, auth }) => {
  const { locale, direction } =  theme;
  const { token } = auth;
  return { locale, token, direction }
};

export default withRouter(connect(mapStateToProps)(Views));
