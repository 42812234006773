import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig'
import {ROLE_MASTER, ROLE_SUPERVISOR} from "redux/constants/Auth";
import RoleBasedRouting from "auth/gard/RoleBasedRouting";

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content"/>}>
      <Switch>
          <RoleBasedRouting
              AuthNeeded={[ROLE_SUPERVISOR , ROLE_MASTER]}
              path={`${APP_PREFIX_PATH}/users`}
              component={lazy(() => import(`./users`))}
          />
          <RoleBasedRouting
              AuthNeeded={ROLE_MASTER}
              path={`${APP_PREFIX_PATH}/products`}
              component={lazy(() => import(`./products`))}
          />
          <RoleBasedRouting
              AuthNeeded={ROLE_MASTER}
              path={`${APP_PREFIX_PATH}/promo`}
              component={lazy(() => import(`./promo`))}
          />
          <RoleBasedRouting
              AuthNeeded={ROLE_MASTER}
              path={`${APP_PREFIX_PATH}/category`}
              component={lazy(() => import(`./category`))}
          />
          <RoleBasedRouting
              AuthNeeded={ROLE_MASTER}
              path={`${APP_PREFIX_PATH}/orders`}
              component={lazy(() => import(`./orders`))}
          />
          <RoleBasedRouting
              AuthNeeded={ROLE_MASTER}
              path={`${APP_PREFIX_PATH}/finance`}
              component={lazy(() => import(`./finance`))}
          />
          <RoleBasedRouting
              AuthNeeded={ROLE_MASTER}
              path={`${APP_PREFIX_PATH}/bonus`}
              component={lazy(() => import(`./bonus`))}
          />
          <RoleBasedRouting
              AuthNeeded={ROLE_MASTER}
              path={`${APP_PREFIX_PATH}/discounts`}
              component={lazy(() => import(`./discount`))}
          />
          <RoleBasedRouting
              AuthNeeded={ROLE_MASTER}
              path={`${APP_PREFIX_PATH}/media`}
              component={lazy(() => import(`./media`))}
          />
          <RoleBasedRouting
              AuthNeeded={ROLE_MASTER}
              path={`${APP_PREFIX_PATH}/reglement`}
              component={lazy(() => import(`./reglement`))}
          />
          <RoleBasedRouting
              AuthNeeded={ROLE_MASTER}
              path={`${APP_PREFIX_PATH}/settings`}
              component={lazy(() => import(`./settings`))}
          />

          <RoleBasedRouting
              AuthNeeded={ROLE_MASTER}
              path={`${APP_PREFIX_PATH}/giftCard`}
              component={lazy(() => import(`./giftCard`))}
          />
          <RoleBasedRouting
              AuthNeeded={ROLE_MASTER}
              path={`${APP_PREFIX_PATH}/challenge`}
              component={lazy(() => import(`./challenges`))}
          />
          <RoleBasedRouting
              AuthNeeded={ROLE_MASTER}
              path={`${APP_PREFIX_PATH}/formations`}
              component={lazy(() => import(`./formation`))}
          />
          <RoleBasedRouting
              AuthNeeded={ROLE_MASTER}
              path={`${APP_PREFIX_PATH}/payments`}
              component={lazy(() => import(`./payments`))}
          />
          <RoleBasedRouting
              AuthNeeded={ROLE_MASTER}
              path={`${APP_PREFIX_PATH}/notifications`}
              component={lazy(() => import(`./notifications`))}
          />
        <Route path={`${APP_PREFIX_PATH}/home`} component={lazy(() => import(`./home`))} />
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/home`} />
      </Switch>
    </Suspense>
  )
}

export default React.memo(AppViews);
