import {
	AUTH_TOKEN,
	AUTHENTICATED,
	SHOW_AUTH_MESSAGE,
	HIDE_AUTH_MESSAGE,
	SIGNOUT_SUCCESS,
	SIGNUP_SUCCESS,
	SHOW_LOADING,
	SIGNIN_WITH_GOOGLE_AUTHENTICATED,
    SIGNIN_WITH_FACEBOOK_AUTHENTICATED,
	SET_USER,
	SIGNIN_SUCCESS
} from '../constants/Auth';

const initState = {
	loading: false,
	message: "",
	showMessage: false,
	redirect: "",
	token: null,
	role: "ROLE_USER",
	username: "",
	code: "",
};

const auth = (state = initState, action) => {
	switch (action.type) {
		case SET_USER: {
			return {
				...state,
				username: action.user.username,
				role: action.user.role,
				code: action.user.code,
				redirect: '/',
			};
		}
		case AUTHENTICATED:
			return {
				...state,
				loading: false,
				redirect: '/app',
				token: action.token
			}
		case SIGNIN_SUCCESS: {
			return {
				...state,
				redirect: "/app",
				message: "",
				showMessage: false,
			};
		}
		case SHOW_AUTH_MESSAGE:
			return {
				...state,
				message: action.message,
				showMessage: true,
				loading: false
			}
		case HIDE_AUTH_MESSAGE:
			return {
				...state,
				message: '',
				showMessage: false,
			}
		case SIGNOUT_SUCCESS: {
			return {
				...state,
				message: "",
				showMessage: false,
				token: null,
				redirect: "/",
				loading: false,
				role: "ROLE_USER",
				username: "",
				code: "",
			};
		}
		case SIGNUP_SUCCESS: {
			return {
			  ...state,
			  loading: false,
			  token: action.token
			}
		}
		case SHOW_LOADING: {
			return {
				...state,
				loading: true
			}
		}
		case SIGNIN_WITH_GOOGLE_AUTHENTICATED: {
			return {
				...state,
				loading: false,
				token: action.token
			}
		}
		case SIGNIN_WITH_FACEBOOK_AUTHENTICATED: {
			return {
				...state,
				loading: false,
				token: action.token
			}
		}
		default:
			return state;
	}
}

export default auth
