import fetch from '../auth/FetchInterceptor'

const PaymentService = {
}
const ServiceURL = "/payment"

PaymentService.getAll = function (data) {
    return fetch({
        url: ServiceURL+'/getAll',
        method: 'post',
        data:data
    })
}

PaymentService.getPaymentMethods = function () {
    return fetch({
        url: ServiceURL+'/getPaymentMethods',
        method: 'get',
    })
}


PaymentService.modifyPaymentMethod = function (data) {
    return fetch({
        url: ServiceURL+'/method/'+data.id,
        method: 'put',
        data : data
    })
}

export default PaymentService
