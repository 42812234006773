import React , {useState } from "react";
import { Link } from "react-router-dom";
import { Menu, Grid } from "antd";
import IntlMessage from "../util-components/IntlMessage";
import Icon from "../util-components/Icon";
import navigationConfig  from "configs/NavigationConfig";
import { connect } from "react-redux";
import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE } from "constants/ThemeConstant";
import utils from "utils";
import { onMobileNavToggle } from "redux/actions/Theme";
import useAuth from "hooks/useAuth";
import {ROLE_MASTER,ROLE_TRAINING, ROLE_MEDIA, ROLE_DIGITAL , ROLE_ORDER, ROLE_PRODUCT, ROLE_FINANCIER, ROLE_SUPERVISOR} from "redux/constants/Auth";

const { SubMenu } = Menu;
const { useBreakpoint } = Grid;



const setLocale = (isLocaleOn, localeKey) =>
    isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();

const setDefaultOpen = (key) => {
  let keyList = [];
  let keyString = "";
  if (key) {
    const arr = key.split("-");
    for (let index = 0; index < arr.length; index++) {
      const elm = arr[index];
      index === 0 ? (keyString = elm) : (keyString = `${keyString}-${elm}`);
      keyList.push(keyString);
    }
  }
  return keyList;
};

const useConstructor = (callBack = () => {}) => {
  const [hasBeenCalled, setHasBeenCalled] = useState(false);
  const data = callBack();
  if (hasBeenCalled) return data;
  setHasBeenCalled(true);
  return data;
};

const SideNavContent = (props) => {
  const {
    sideNavTheme,
    routeInfo,
    hideGroupTitle,
    localization,
    onMobileNavToggle,
  } = props;



  const data = useConstructor(() => {
    const IsAdmin = () => {
      const { adminstration , userRole } = useAuth([]);
      return {adminstration , userRole };
    };
    switch (IsAdmin().userRole) {
        case ROLE_MEDIA:
          return [
            navigationConfig.at(2),
            navigationConfig.at(3) ,
            navigationConfig.at(4) ,
            navigationConfig.at(6),
            navigationConfig.at(7)
          ];
        case ROLE_TRAINING:
          return [
            navigationConfig.at(14),
          ];

        case ROLE_DIGITAL:
          return [
            navigationConfig.at(2),
            navigationConfig.at(3) ,
            navigationConfig.at(4) ,
            navigationConfig.at(6),
          ];

        case ROLE_FINANCIER:
          return [
            navigationConfig.at(9),
            navigationConfig.at(8) ,
            navigationConfig.at(12)
          ];

        case ROLE_PRODUCT:
          return [
              navigationConfig.at(2),
              navigationConfig.at(3) ,
              navigationConfig.at(4) ,
              navigationConfig.at(6),
          ];

        case ROLE_ORDER:
          return [
            navigationConfig.at(8) ,
          ];

        case ROLE_SUPERVISOR:
          return [
            navigationConfig.at(1),
            navigationConfig.at(2),
            navigationConfig.at(3),
            navigationConfig.at(4),
            navigationConfig.at(5),
            navigationConfig.at(6),
            navigationConfig.at(7),
            navigationConfig.at(8),
            navigationConfig.at(9),
            navigationConfig.at(12),
            navigationConfig.at(14),
          ];

      case ROLE_MASTER:
        return navigationConfig

      default:
        return [];
    }

  });

  const IsAuth = (premission) => {
    const { isAuth: isAuthenticated } = useAuth([premission]);
    return isAuthenticated;
  };

  const ExactAuth = (premission) => {
    const { exact } = useAuth([premission]);
    return exact;
  };



  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes("lg");
  const closeMobileNav = () => {
    if (isMobile) {
      onMobileNavToggle(false);
    }
  };

  return (
      <Menu
          theme={sideNavTheme === SIDE_NAV_LIGHT ? "light" : "dark"}
          mode="inline"
          style={{ height: "100%", borderRight: 0 }}
          defaultSelectedKeys={[routeInfo?.key]}
          defaultOpenKeys={setDefaultOpen(routeInfo?.key)}
          className={hideGroupTitle ? "hide-group-title" : ""}
      >
        {data.map((menu) =>
            menu.submenu.length > 0 ? (
                menu.exactAuth !== true ? (
                    IsAuth(menu.premission) ? (
                        <Menu.ItemGroup
                            key={menu.key}
                            title={setLocale(localization, menu.title)}
                        >
                          {menu.submenu.map((subMenuFirst) =>
                              subMenuFirst.submenu.length > 0 ? (
                                  IsAuth(subMenuFirst.premission) ? (
                                      <SubMenu
                                          icon={
                                            subMenuFirst.icon ? (
                                                <Icon type={subMenuFirst?.icon} />
                                            ) : null
                                          }
                                          key={subMenuFirst.key}
                                          title={setLocale(localization, subMenuFirst.title)}
                                      >
                                        {subMenuFirst.submenu.map((subMenuSecond) =>
                                                IsAuth(subMenuSecond.premission) ? (
                                                    <Menu.Item key={subMenuSecond.key}>
                                                      {subMenuSecond.icon ? (
                                                          <Icon type={subMenuSecond?.icon} />
                                                      ) : null}
                                                      <span>
                                {setLocale(localization, subMenuSecond.title)}
                              </span>
                                                      <Link
                                                          onClick={() => closeMobileNav()}
                                                          to={subMenuSecond.path}
                                                      />
                                                    </Menu.Item>
                                                ) : null
                                        )}
                                      </SubMenu>
                                  ) : null
                              ) : IsAuth(subMenuFirst.premission) ? (
                                  <Menu.Item key={subMenuFirst.key}>
                                    {subMenuFirst.icon ? (
                                        <Icon type={subMenuFirst.icon} />
                                    ) : null}
                                    <span>{setLocale(localization, subMenuFirst.title)}</span>
                                    <Link
                                        onClick={() => closeMobileNav()}
                                        to={subMenuFirst.path}
                                    />
                                  </Menu.Item>
                              ) : null
                          )}
                        </Menu.ItemGroup>
                    ) : null
                ) : ExactAuth(menu.premission) ? (
                    <Menu.ItemGroup
                        key={menu.key}
                        title={setLocale(localization, menu.title)}
                    >
                      {menu.submenu.map((subMenuFirst) =>
                          subMenuFirst.submenu.length > 0 ? (
                              IsAuth(subMenuFirst.premission) ? (
                                  <SubMenu
                                      icon={
                                        subMenuFirst.icon ? (
                                            <Icon type={subMenuFirst?.icon} />
                                        ) : null
                                      }
                                      key={subMenuFirst.key}
                                      title={setLocale(localization, subMenuFirst.title)}
                                  >
                                    {subMenuFirst.submenu.map((subMenuSecond) =>
                                            IsAuth(subMenuSecond.premission) ? (
                                                <Menu.Item key={subMenuSecond.key}>
                                                  {subMenuSecond.icon ? (
                                                      <Icon type={subMenuSecond?.icon} />
                                                  ) : null}
                                                  <span>
                              {setLocale(localization, subMenuSecond.title)}
                            </span>
                                                  <Link
                                                      onClick={() => closeMobileNav()}
                                                      to={subMenuSecond.path}
                                                  />
                                                </Menu.Item>
                                            ) : null
                                    )}
                                  </SubMenu>
                              ) : null
                          ) : IsAuth(subMenuFirst.premission) ? (
                              <Menu.Item key={subMenuFirst.key}>
                                {subMenuFirst.icon ? (
                                    <Icon type={subMenuFirst.icon} />
                                ) : null}
                                <span>{setLocale(localization, subMenuFirst.title)}</span>
                                <Link
                                    onClick={() => closeMobileNav()}
                                    to={subMenuFirst.path}
                                />
                              </Menu.Item>
                          ) : null
                      )}
                    </Menu.ItemGroup>
                ) : null
            ) : IsAuth(menu.premission) ? (
                <Menu.Item key={menu.key}>
                  {menu.icon ? <Icon type={menu?.icon} /> : null}
                  <span>{setLocale(localization, menu?.title)}</span>
                  {menu.path ? (
                      <Link onClick={() => closeMobileNav()} to={menu.path} />
                  ) : null}
                </Menu.Item>
            ) : null
        )}
      </Menu>
  );
};

const TopNavContent = (props) => {
  const { topNavColor, localization } = props;
  return (
      <Menu mode="horizontal" style={{ backgroundColor: topNavColor }}>
        {navigationConfig.map((menu) =>
            menu.submenu.length > 0 ? (
                <SubMenu
                    key={menu.key}
                    popupClassName="top-nav-menu"
                    title={
                      <span>
                {menu.icon ? <Icon type={menu?.icon} /> : null}
                        <span>{setLocale(localization, menu.title)}</span>
              </span>
                    }
                >
                  {menu.submenu.map((subMenuFirst) =>
                      subMenuFirst.submenu.length > 0 ? (
                          <SubMenu
                              key={subMenuFirst.key}
                              icon={
                                subMenuFirst.icon ? (
                                    <Icon type={subMenuFirst?.icon} />
                                ) : null
                              }
                              title={setLocale(localization, subMenuFirst.title)}
                          >
                            {subMenuFirst.submenu.map((subMenuSecond) => (
                                <Menu.Item key={subMenuSecond.key}>
                      <span>
                        {setLocale(localization, subMenuSecond.title)}
                      </span>
                                  <Link to={subMenuSecond.path} />
                                </Menu.Item>
                            ))}
                          </SubMenu>
                      ) : (
                          <Menu.Item key={subMenuFirst.key}>
                            {subMenuFirst.icon ? (
                                <Icon type={subMenuFirst?.icon} />
                            ) : null}
                            <span>{setLocale(localization, subMenuFirst.title)}</span>
                            <Link to={subMenuFirst.path} />
                          </Menu.Item>
                      )
                  )}
                </SubMenu>
            ) : (
                <Menu.Item key={menu.key}>
                  {menu.icon ? <Icon type={menu?.icon} /> : null}
                  <span>{setLocale(localization, menu?.title)}</span>
                  {menu.path ? <Link to={menu.path} /> : null}
                </Menu.Item>
            )
        )}
      </Menu>
  );
};

const MenuContent = (props) => {
  return props.type === NAV_TYPE_SIDE ? (
      <SideNavContent {...props} />
  ) : (
      <TopNavContent {...props} />
  );
};

const mapStateToProps = ({ theme }) => {
  const { sideNavTheme, topNavColor } = theme;
  return { sideNavTheme, topNavColor };
};

export default connect(mapStateToProps, { onMobileNavToggle })(MenuContent);
