import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {
	SIGNIN,
	SIGNOUT,
	SIGNUP,
	SIGNIN_WITH_GOOGLE,
	SIGNIN_WITH_FACEBOOK,
	ROLE_CUSTOMER,
} from "../constants/Auth";
import {
	showAuthMessage,
	authenticated,
	signOutSuccess,
	signUpSuccess,
	signInSuccess,
	setUser,
} from "../actions/Auth";

import JwtAuthService from "services/JwtAuthService";



export function* signInwithJWT() {
	yield takeEvery(SIGNIN, function* ({ payload }) {
		const { username, password } = payload;
		try {
			const user = yield call(JwtAuthService.login, {
				username: username,
				password: password,
			});
			if (user.status === 1){

				yield put(
					setUser({
						username: user.user,
						role: user.role,
						code: user.code,
					})
				);

				if (user.role === ROLE_CUSTOMER) {
					yield put(signOutSuccess());
				} else {
					yield put(authenticated(true));
					yield put(signInSuccess());
				}
			}else{
				yield put(showAuthMessage("Invalid credentials."));
			}

		} catch (err) {
			yield put(showAuthMessage(err));
		}
	});
}

export function* signOut() {
	yield takeEvery(SIGNOUT, function* () {
		try {
			yield put(signOutSuccess());
			const signOut = yield call(JwtAuthService.signOut);
		} catch (err) {
			yield put(showAuthMessage(err));
		}
	});
}




export default function* rootSaga() {
	yield all([
		fork(signInwithJWT),
		fork(signOut),
	]);
}
