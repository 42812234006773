import {
  DashboardOutlined,
  UserOutlined,
  WalletOutlined,
  ShoppingCartOutlined,
  SettingOutlined,
  CloudUploadOutlined,
  TeamOutlined,
  UserAddOutlined ,
  IdcardOutlined  ,
  LineChartOutlined   ,
  ApartmentOutlined   ,
  FundProjectionScreenOutlined    ,
  SubnodeOutlined     ,
  AppstoreOutlined   ,
  AppstoreAddOutlined   ,
  MailOutlined    ,
  ContainerOutlined     ,
  FieldTimeOutlined     ,
  SnippetsOutlined     ,
  PercentageOutlined      ,
  PlusCircleOutlined       ,
  CreditCardOutlined       ,
  TagsOutlined        ,
  DeliveredProcedureOutlined          ,
  SoundOutlined          ,
  GiftOutlined           ,
  FileWordOutlined           ,
  TrophyOutlined,
  ScheduleOutlined,
  RocketOutlined,
  AuditOutlined,
  ShoppingOutlined,
  TrademarkOutlined,
  MessageOutlined
} from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig'

const dashBoardNavTree = [
    {
    key: 'dashboard',
    path: `${APP_PREFIX_PATH}/dashboard`,
    title: 'Dashboard',
    icon: DashboardOutlined ,
    breadcrumb: false,
    submenu: [
      {
        key: 'dashboard',
        path: `${APP_PREFIX_PATH}/dashboard`,
        title: 'Dashboard',
        icon: DashboardOutlined ,
        breadcrumb: false,
        submenu: []
      },
    ]
  },
    {
      key: 'users',
      path: `${APP_PREFIX_PATH}/users`,
      title: 'User Management',
      icon: UserOutlined ,
      breadcrumb: false,
      submenu: [
        {
          key: 'user-add',
          path: `${APP_PREFIX_PATH}/users/add`,
          title: 'add Administrator',
          icon: UserAddOutlined  ,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'user-getAdmins',
          path: `${APP_PREFIX_PATH}/users/admins`,
          title: 'All Admins',
          icon: IdcardOutlined  ,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'user-getAll',
          path: `${APP_PREFIX_PATH}/users/All`,
          title: 'All Client',
          icon: TeamOutlined  ,
          breadcrumb: false,
          submenu: []
        }
      ]
    },
    {
      key: 'product',
      path: `${APP_PREFIX_PATH}/products`,
      title: 'Products Management',
      icon: ShoppingCartOutlined ,
      breadcrumb: false,
      submenu: [
        {
          key: 'product-getAll',
          path: `${APP_PREFIX_PATH}/products/all`,
          title: 'All Products',
          icon: AppstoreOutlined   ,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'product-add',
          path: `${APP_PREFIX_PATH}/products/add`,
          title: 'add Product',
          icon: AppstoreAddOutlined    ,
          breadcrumb: false,
          submenu: []
        },
      ]
    },
  {
    key: 'category',
    path: `${APP_PREFIX_PATH}/category`,
    title: 'Category Management',
    icon: ShoppingCartOutlined ,
    breadcrumb: false,
    submenu: [
      {
        key: 'category-getAll',
        path: `${APP_PREFIX_PATH}/category/all`,
        title: 'All Category',
        icon: TagsOutlined    ,
        breadcrumb: false,
        submenu: []
      },
    ]
  },
    {
      key: 'promo',
      path: `${APP_PREFIX_PATH}/promo`,
      title: 'Promos Management',
      icon: ShoppingCartOutlined ,
      breadcrumb: false,
      submenu: [
        {
          key: 'promo-welcome-gift',
          path: `${APP_PREFIX_PATH}/promo/welcome`,
          title: 'Welcome Gift',
          icon: SoundOutlined     ,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'promo-gratuity-all',
          path: `${APP_PREFIX_PATH}/promo/all`,
          title: 'All Gratuity',
          icon: GiftOutlined      ,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'promo-gratuity-add',
          path: `${APP_PREFIX_PATH}/promo/gratuity`,
          title: 'Add Gratuity',
          icon: GiftOutlined      ,
          breadcrumb: false,
          submenu: []
        },
      ]
    },
    {
      key: 'gift-card',
      path: `${APP_PREFIX_PATH}/giftCard`,
      title: 'Gift Card Management',
      icon: ShoppingCartOutlined ,
      breadcrumb: false,
      submenu: [
        {
          key: 'gift-card-all',
          path: `${APP_PREFIX_PATH}/giftCard/all`,
          title: 'Gift Card',
          icon: ShoppingOutlined        ,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'gift-card-add',
          path: `${APP_PREFIX_PATH}/giftCard/add`,
          title: 'Add Gift Card',
          icon: PlusCircleOutlined       ,
          breadcrumb: false,
          submenu: []
        },
      ]
    },
    {
      key: 'discount',
      path: `${APP_PREFIX_PATH}/discounts`,
      title: 'Discounts Management',
      icon: ShoppingCartOutlined ,
      breadcrumb: false,
      submenu: [
        {
          key: 'discount-getAll',
          path: `${APP_PREFIX_PATH}/discounts/all`,
          title: 'All Discounts',
          icon: PercentageOutlined    ,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'discount-add',
          path: `${APP_PREFIX_PATH}/discounts/add`,
          title: 'add Discounts',
          icon: PlusCircleOutlined      ,
          breadcrumb: false,
          submenu: []
        },
      ]
    },
    {
      key: 'media',
      path: `${APP_PREFIX_PATH}/media`,
      title: 'Media Management',
      icon: CloudUploadOutlined ,
      breadcrumb: false,
      submenu: [
        {
          key: 'media',
          path: `${APP_PREFIX_PATH}/media/uploadCenter`,
          title: 'Upload Center',
          icon: CloudUploadOutlined ,
          breadcrumb: false,
          submenu: []
        },
      ]
    },
    {
      key: 'order',
      path: `${APP_PREFIX_PATH}/orders`,
      title: 'Order Management',
      icon: WalletOutlined ,
      breadcrumb: false,
      submenu: [
        {
          key: 'order-all',
          path: `${APP_PREFIX_PATH}/orders/all`,
          title: 'Order Management',
          icon: WalletOutlined ,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'order-command',
          path: `${APP_PREFIX_PATH}/orders/command`,
          title: 'Command Management',
          icon: SnippetsOutlined  ,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'order-delivery',
          path: `${APP_PREFIX_PATH}/orders/delivery`,
          title: 'Delivery Management',
          icon: FieldTimeOutlined  ,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'order-invoice',
          path: `${APP_PREFIX_PATH}/orders/invoice`,
          title: 'Payment Management',
          icon: ContainerOutlined  ,
          breadcrumb: false,
          submenu: []
        },
      ]
    },
    {
      key: 'bonusTransaction',
      path: `${APP_PREFIX_PATH}/bonus`,
      title: 'Bonus Management',
      icon: SettingOutlined ,
      breadcrumb: false,
      submenu: [

        {
          key: 'bonusTransaction-grad',
          path: `${APP_PREFIX_PATH}/bonus/grad`,
          title: 'Bonus Level',
          icon: ApartmentOutlined ,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'bonusTransaction-gen',
          path: `${APP_PREFIX_PATH}/bonus/generation`,
          title: 'Bonus affiliation',
          icon: SubnodeOutlined,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'bonusTransaction-drop',
          path: `${APP_PREFIX_PATH}/bonus/drop`,
          title: 'Bonus DropShipping',
          icon: ShoppingCartOutlined  ,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'bonusTransaction-restore',
          path: `${APP_PREFIX_PATH}/bonus/restore`,
          title: 'Bonus restoration',
          icon: DeliveredProcedureOutlined  ,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'bonusTransaction-coaching',
          path: `${APP_PREFIX_PATH}/bonus/coaching`,
          title: 'Bonus Coaching',
          icon: RocketOutlined   ,
          breadcrumb: false,
          submenu: []
        },
      ]
    },
    {
      key: 'settings',
      path: `${APP_PREFIX_PATH}/settings`,
      title: 'Settings Management',
      icon: SettingOutlined ,
      breadcrumb: false,
      submenu: [
        {
          key: 'settings-all',
          path: `${APP_PREFIX_PATH}/settings`,
          title: 'Settings Management',
          icon: SettingOutlined ,
          breadcrumb: false,
          submenu: []
        },
      ]
    },
    {
    key: 'Reglement',
    path: `${APP_PREFIX_PATH}/reglement`,
    title: 'Reglement Management',
    icon: TrademarkOutlined  ,
    breadcrumb: false,
    submenu: [
      {
        key: 'Reglement-add',
        path: `${APP_PREFIX_PATH}/reglement/add`,
        title: 'add Reglement',
        icon: FundProjectionScreenOutlined  ,
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'Reglement-list',
        path: `${APP_PREFIX_PATH}/reglement/list`,
        title: 'list Reglement',
        icon: TrademarkOutlined  ,
        breadcrumb: false,
        submenu: [],
      }
    ]
  },
    {
      key: 'finance',
      path: `${APP_PREFIX_PATH}/finance`,
      title: 'Finance Management',
      icon: SnippetsOutlined  ,
      breadcrumb: false,
      submenu: [
        {
          key: 'finance',
          path: `${APP_PREFIX_PATH}/finance`,
          title: 'state of sales',
          icon: LineChartOutlined   ,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'export-rs',
          path: `${APP_PREFIX_PATH}/reglement/rs`,
          title: 'export RS',
          icon: FileWordOutlined  ,
          breadcrumb: false,
          submenu: []
        },
      ]
    },
  {
    key: 'challenge',
    path: `${APP_PREFIX_PATH}/challenge`,
    title: 'Challenges Management',
    icon: TrophyOutlined ,
    breadcrumb: false,
    submenu: [
      {
        key: 'challenge-all',
        path: `${APP_PREFIX_PATH}/challenge/all`,
        title: 'Challenges List',
        icon: TrophyOutlined       ,
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'challenge-add',
        path: `${APP_PREFIX_PATH}/challenge/add`,
        title: 'Add Challenge',
        icon: PlusCircleOutlined       ,
        breadcrumb: false,
        submenu: []
      },
    ]
  },
  {
    key: 'formation',
    path: `${APP_PREFIX_PATH}/formations`,
    title: 'Arbio Training',
    icon: ShoppingCartOutlined ,
    breadcrumb: false,
    submenu: [
      {
        key: 'formation-getAll',
        path: `${APP_PREFIX_PATH}/formations/all`,
        title: 'All formation',
        icon: AppstoreOutlined   ,
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'formation-add',
        path: `${APP_PREFIX_PATH}/formations/add`,
        title: 'add Formation',
        icon: AppstoreAddOutlined    ,
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'formation-tickets',
        path: `${APP_PREFIX_PATH}/formations/tickets`,
        title: 'Ticket Formation',
        icon: ScheduleOutlined    ,
        breadcrumb: false,
        submenu: []
      },
    ]
  },
  {
    key: 'payment',
    path: `${APP_PREFIX_PATH}/payments`,
    title: 'Payment Management',
    icon: ShoppingCartOutlined ,
    breadcrumb: false,
    submenu: [
      {
        key: 'payment-getAll',
        path: `${APP_PREFIX_PATH}/payments/all`,
        title: 'All Payments',
        icon: CreditCardOutlined   ,
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'payment-method',
        path: `${APP_PREFIX_PATH}/payments/methods`,
        title: 'Payment methods',
        icon: AuditOutlined     ,
        breadcrumb: false,
        submenu: []
      }
    ]
  },
  {
    key: 'notification',
    path: `${APP_PREFIX_PATH}/notifications`,
    title: 'Notification Management',
    icon: MailOutlined  ,
    breadcrumb: false,
    submenu: [
      {
        key: 'notification-getAll',
        path: `${APP_PREFIX_PATH}/notifications/all`,
        title: 'All Notifications',
        icon: MailOutlined   ,
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'notification-settings',
        path: `${APP_PREFIX_PATH}/notifications/settings`,
        title: 'Notification settings',
        icon: MessageOutlined      ,
        breadcrumb: false,
        submenu: []
      }
    ]
  },
  ]

const navigationConfig = [
  ...dashBoardNavTree
]

export default navigationConfig;
